@import '@/styles/variables';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 940px;
  margin: 0 auto;

  &.article {
    max-width: calc(800px + 2rem);
    padding: 0 1rem;
  }

  &.guide {
    max-width: 1000px;
  }

  &.small {
    max-width: 600px;
  }

  &.wide {
    max-width: 1400px;
  }
}
